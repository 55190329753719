export default [
 
  // { header: 'Configuration',},
  // {
  //   title: 'Admin Users',
  //   route: 'admin-users-page',
  //   icon: 'FileIcon',
  // },
  // {
  //   title: 'Settings',
  //   route: 'settings-page',
  //   icon: 'ShieldIcon',
  // }

{ header: 'Client Management',},
  {
    title: 'Clients',
    route: 'clients',
    icon: '',
  },
  {
    title: 'Projects',
    route: 'projects',
    icon: '',
  },
  {
    title: 'Subscriptions',
    route: 'subscriptions',
    icon: '',
  },
  {
    title: 'Modules',
    route: 'modules',
    icon: '',
  },
  {
    title: 'Super Admin Users',
    route: 'super-admin-users',
    icon: 'ShieldIcon',
  }
 
]
